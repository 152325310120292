<template>
  <div class="material-block" :class="[`material-block--${size}`]">
    <div v-if="block.type === 'block'">
      <simple-text-title :content="block.title"></simple-text-title>
      <simple-text-content
        :content="block.content"
        v-if="block.content.type === 'simple-text'"></simple-text-content>
      <rich-text-content
        :content="block.content"
        v-else-if="block.content.type === 'rich-text'"></rich-text-content>
      <image-content
        :content="block.content"
        v-else-if="block.content.type === 'image'"/>
    </div>
    <div v-else>
      <material-block
        v-for="(subBlock, index) in getContents()"
        :key="index"
        :block="subBlock"></material-block>
    </div>
  </div>
</template>

<script>
import SimpleTextContent from "./SimpleTextContent";
import RichTextContent from "./RichTextContent";
import SimpleTextTitle from "./SimpleTextTitle";
import ImageContent from "./ImageContent";

export default {
  props: {
    block: {
      type: Object,
      required: true
    },
    /**
     * size: medium(default) | small
     */
    size: {
      type: String,
      default: "medium"
    }
  },
  components: {
    SimpleTextContent,
    RichTextContent,
    SimpleTextTitle,
    ImageContent
  },
  methods: {
    getContents() {
      return Object.values(this.block.contents || {});
    }
  }
};
</script>
