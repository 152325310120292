<template>
  <div class="image-content">
    <div v-for="(img, index) in images" :key="index">
      <img :src="img" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    content: Object
  },
  computed: {
    images() {
      const content = this.content.content;
      if (content && content.startsWith("[")) {
        return JSON.parse(content);
      }
      return content ? [content] : [];
    }
  }
};
</script>
