<template>
  <div class="simple-content">
    {{ content.content }}
  </div>
</template>

<script>
export default {
  props: {
    content: Object
  }
};
</script>
