<template>
  <div
    class="rich-content material-content-preview"
    v-html="content.content"></div>
</template>

<script>
export default {
  props: {
    content: Object
  }
};
</script>
