<template>
  <div class="simple-title">{{ content.content }}</div>
</template>

<script>
export default {
  props: {
    content: {
      type: Object
    }
  }
};
</script>
